import { v4 as uuidv4 } from "uuid";
import { apolloClient } from "@/apollo";

import RECORD_OF_OPENING from "@/gql/mutation/analytics/recordOfOpening.gql";
import RECORD_OF_QR_CODE_SCAN from "@/gql/mutation/analytics/recordOfQrCodeScan.gql";
import RECORD_OF_SAVING_TO_BOOKMARKS from "@/gql/mutation/analytics/recordOfSavingToBookmarks.gql";
import RECORD_OF_USAGE_TIME from "@/gql/mutation/analytics/recordOfUsageTime.gql";

export default {
  namespaced: true,
  state: {
    session: {
      id: null,
      timestamp: null,
    },
  },
  getters: {
    session(state) {
      return state.session;
    },
  },
  mutations: {
    setSession(state, data) {
      state.session = data;
    },
  },
  actions: {
    sendAnalytics(
      { getters, rootGetters },
      { mutation = null, variables = {} },
    ) {
      apolloClient.mutate({
        mutation,
        variables: {
          sessionId: getters.session.id,
          restaurant: rootGetters["restaurant"].id,
          ...variables,
        },
      });
    },
    sendAnalyticsBookmark({ dispatch }, data) {
      dispatch("sendAnalytics", {
        mutation: RECORD_OF_SAVING_TO_BOOKMARKS,
        variables: { menuItem: data },
      });
    },
    sendAnalyticsOpenItem({ dispatch }, data) {
      dispatch("sendAnalytics", {
        mutation: RECORD_OF_OPENING,
        variables: { menuItem: data },
      });
    },
    setupAnalytics({ getters, commit, dispatch }) {
      const sessionTimeInMs = 5 * 60 * 60 * 1000; // 5 hours
      if (
        !getters.session.id ||
        Date.now() - getters.session.timestamp > sessionTimeInMs
      ) {
        commit("setSession", { id: uuidv4(), timestamp: Date.now() });
        dispatch("sendAnalytics", { mutation: RECORD_OF_QR_CODE_SCAN });
      }
      setInterval(() => {
        commit("setSession", { id: uuidv4(), timestamp: Date.now() });
        dispatch("sendAnalytics", { mutation: RECORD_OF_QR_CODE_SCAN });
      }, sessionTimeInMs);

      dispatch("sendAnalytics", { mutation: RECORD_OF_USAGE_TIME });
      setInterval(() => {
        if (!document.hidden) {
          dispatch("sendAnalytics", { mutation: RECORD_OF_USAGE_TIME });
        }
      }, 30 * 1000); // 30 seconds
    },
  },
};
