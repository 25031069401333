import { createI18n } from "vue-i18n";

import bg from "./messages/bg.json";
import en from "./messages/en.json";
import es from "./messages/es.json";
import ru from "./messages/ru.json";
import uk from "./messages/uk.json";

import store from "../store";

export default createI18n({
  locale: store.getters["locale"],
  fallbackLocale: "eng",
  messages: {
    bul: bg,
    eng: en,
    spa: es,
    rus: ru,
    ukr: uk,
  },
});
