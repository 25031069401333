import { createApp } from "vue";
import App from "./App.vue";

import router from "./router";
import store from "./store";

import Vue3TouchEvents from "vue3-touch-events";
import { createMetaManager, plugin as vueMetaPlugin } from "vue-meta";
import i18n from "./i18n";

import { apolloProvider } from "./apollo";

import "normalize.css";

createApp(App)
  .use(router)
  .use(store)
  .use(apolloProvider)
  .use(Vue3TouchEvents)
  .use(createMetaManager())
  .use(vueMetaPlugin)
  .use(i18n)
  .mount("#app");
