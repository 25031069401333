function getFormattedPrice(val) {
  const value = val || 0;

  const valueFloat = parseFloat(value);

  if (Number.isNaN(valueFloat)) {
    return value;
  }

  return valueFloat.toFixed(2);
}

export { getFormattedPrice };
