import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "",
    name: "index",
    component: () =>
      import(/* webpackChunkName: "Index" */ "../views/Index.vue"),
  },
  {
    path: "/:mode",
    name: "restaurant",
    component: () =>
      import(/* webpackChunkName: "Restaurant" */ "../views/Restaurant.vue"),
  },
  {
    component: () =>
      import(/* webpackChunkName: "Default" */ "../layouts/Default.vue"),
    children: [
      {
        path: "/:mode/catalog",
        name: "catalog",
        component: () =>
          import(/* webpackChunkName: "Catalog" */ "../views/Catalog.vue"),
      },
      {
        path: "/:mode/item/:id",
        name: "item",
        component: () =>
          import(/* webpackChunkName: "Item" */ "../views/Item.vue"),
      },
      {
        path: "/:mode/order",
        name: "order",
        component: () =>
          import(/* webpackChunkName: "Order" */ "../views/Order.vue"),
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    name: "error",
    component: () =>
      import(/* webpackChunkName: "Error" */ "../views/Error.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.name === "catalog" && savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

export default router;
