import { v4 as uuidv4 } from "uuid";
import { stringifyOrderItem } from "@/order";
import { getFormattedPrice } from "@/formatter";

export default {
  namespaced: true,
  state: {
    items: [],
  },
  getters: {
    items(state) {
      return state.items;
    },
    itemsToRender(state, getters, rootState, rootGetters) {
      if (!rootGetters.itemsAll.length) {
        return [];
      }

      return getters.items.map((i) => {
        const item = rootGetters.itemsAll.find((j) => j.id === i.offerId);

        const options = i.options
          .map((j) => {
            const option = item.options.find((k) =>
              k.items.find((m) => m.id === j),
            );
            if (!option) {
              return null;
            }
            return {
              ...option.items.find((k) => k.id === j),
              type: option.type,
            };
          })
          .filter((j) => j);

        const extras = i.extras.map((j) =>
          item.extras
            .flatMap((k) => k.items)
            .find((k) => k.originalMenuItemId === j || k.id === j),
        );

        let price = 0;
        price += +item.price;
        options.forEach((j) => {
          price += +j.addPrice;
        });
        extras.forEach((j) => {
          price += +j.addPrice;
        });

        return {
          id: i.id,
          quantity: i.quantity,
          offerId: i.offerId,
          name: item.name,
          options,
          extras,
          price: getFormattedPrice(price * i.quantity),
        };
      });
    },
    itemsQuantity(state, getters) {
      return getters.itemsToRender.reduce((i, j) => i + j.quantity, 0);
    },
    itemsPrice(state, getters) {
      const result = getters.itemsToRender.reduce((i, j) => i + +j.price, 0);
      return getFormattedPrice(result);
    },
  },
  mutations: {
    setOrderItems(state, data) {
      state.items = data;
    },
  },
  actions: {
    addToOrderItem({ getters, commit, dispatch }, data) {
      const toCompare = stringifyOrderItem(data);
      const orderItem = getters.items.find(
        (i) => stringifyOrderItem(i) === toCompare,
      );

      if (orderItem) {
        dispatch("updateOrderItemQuantity", {
          id: orderItem.id,
          isAdd: true,
        });
      } else {
        commit("setOrderItems", [
          ...getters.items,
          {
            id: uuidv4(),
            quantity: 1,
            ...data,
          },
        ]);
      }
    },
    removeOrderItem({ getters, commit }, data) {
      commit(
        "setOrderItems",
        getters.items.filter((i) => i.id !== data),
      );
    },
    updateOrderItemQuantity({ getters, commit }, data) {
      commit(
        "setOrderItems",
        getters.items.map((i) =>
          i.id === data.id
            ? { ...i, quantity: data.isAdd ? i.quantity + 1 : i.quantity - 1 }
            : i,
        ),
      );
    },
  },
};
