import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      eventTouches: [null, null],
      touchDistanceLast: 0,
    };
  },
  computed: {
    ...mapGetters({
      fontSizeCoefficientZoom: "fontSizeCoefficientZoom",
    }),
  },
  mounted() {
    document.addEventListener("touchstart", this.onTouchStart);
  },
  watch: {
    fontSizeCoefficientZoom: {
      handler(newVal) {
        const { style } = document.documentElement;
        style.setProperty("--font-size-coefficient-zoom", newVal);
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions({
      setFontSizeCoefficientZoom: "setFontSizeCoefficientZoom",
    }),
    getTouchDistance() {
      if (!this.eventTouches[0] || !this.eventTouches[1]) {
        return 0;
      }

      const x = this.eventTouches[1].clientX - this.eventTouches[0].clientX;
      const y = this.eventTouches[1].clientY - this.eventTouches[0].clientY;
      return parseInt(Math.sqrt(x * x + y * y).toString());
    },
    onTouchStart(event) {
      if (event.touches.length === 2) {
        this.eventTouches[0] = event.touches[0];
        this.eventTouches[1] = event.touches[1];
        this.touchDistanceLast = this.getTouchDistance();

        document.addEventListener("touchmove", this.onTouchMove, {
          passive: false,
        });
        document.addEventListener("touchend", this.onTouchEnd);
        document.addEventListener("touchcancel", this.onTouchEnd);
        document.body.classList.add("body-disable-scroll");
      }
    },
    onTouchMove(event) {
      if (
        event.touches.length === 2 &&
        this.eventTouches[0].identifier === event.touches[0].identifier &&
        this.eventTouches[1].identifier === event.touches[1].identifier
      ) {
        this.eventTouches[0] = event.touches[0];
        this.eventTouches[1] = event.touches[1];

        if (event.cancelable) {
          event.preventDefault();
        }

        const touchDistance = this.getTouchDistance();
        if (touchDistance) {
          const touchDistanceDelta = touchDistance - this.touchDistanceLast;
          this.touchDistanceLast = touchDistance;
          const coefficient =
            this.fontSizeCoefficientZoom +
            touchDistanceDelta / event.target.clientWidth;
          const maxCoefficient = 1.5;

          if (coefficient < 1) {
            this.setFontSizeCoefficientZoom(1);
          } else if (coefficient > maxCoefficient) {
            this.setFontSizeCoefficientZoom(maxCoefficient);
          } else {
            this.setFontSizeCoefficientZoom(coefficient);
          }
        }
      }
    },
    onTouchEnd(event) {
      if (event.touches.length !== 2) {
        document.removeEventListener("touchmove", this.onTouchMove);
        document.removeEventListener("touchend", this.onTouchEnd);
        document.removeEventListener("touchcancel", this.onTouchEnd);
        document.body.classList.remove("body-disable-scroll");
      }
    },
  },
};
